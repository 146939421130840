<template>
	<div>
		<main-content />
	</div>
</template>

<script>
import MainContent from './MainContent.vue'

export default {
	components: {
		MainContent,
	},
	setup() {
		window.ChannelIO('shutdown')
	},
}
</script>
<style></style>
