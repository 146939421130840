<template>
	<div class="footer-wrapper">
		<v-container>
			<div class="flex contact-us" style="width: 100%">
				<v-card flat style="background-color: rgb(244, 244, 248)">
					<v-card-title class="d-flex align-start justify-space-between px-0">
						<div class="d-flex align-start mb-4">
							<v-img
								:src="require('@/assets/logo/logo.png')"
								contain
								width="46px"
							/>
							<div class="contact-us-content">
								<div class="title">CONTACT US</div>
								<div class="text">마이페이지 상담문의를 통해 문의해주세요</div>
							</div>
						</div>
						<div class="info-box">
							<div class="term d-flex">
								<router-link
									:to="{
										name: 'term',
										params: {
											type: 'service-next',
										},
									}"
									class="text-decoration-none"
								>
									이용 약관
								</router-link>
								<div class="vertical-line"></div>
								<router-link
									:to="{
										name: 'term',
										params: {
											type: 'privacy',
										},
									}"
									class="text-decoration-none"
								>
									개인정보 처리방침
								</router-link>
							</div>
							<small class="company-info">
								{{ companyInfo.name }}
								<br />
								대표 {{ companyInfo.ceoName }}
								<br />
								사업자 등록번호 {{ companyInfo.registrationNumber }} |
								통신판매업 {{ companyInfo.mailOrderNumber }}
								<br />
								위치 {{ companyInfo.address }}
								<br />
								{{ companyInfo.tel }}
							</small>
						</div>
					</v-card-title>
				</v-card>
			</div>
		</v-container>
	</div>
</template>
<script>
import { ref } from '@vue/composition-api'

export default {
	setup() {
		const companyInfo = ref({
			name: '포도나무랩 주식회사',
			ceoName: '이원용',
			registrationNumber: '232-81-06139',
			mailOrderNumber: '2022-서울서초-1472',
			address: '서울시 서초구 바우뫼로7길 8 우면종합상가 801호',
			tel: '070-4138-2288',
		})

		return {
			companyInfo,
		}
	},
}
</script>
<style lang="scss" scoped>
.footer-wrapper {
	padding: 52px 70px;
	box-sizing: border-box;

	@media only screen and (max-width: 975px) {
		padding: 0;
	}

	.container {
		max-width: 1117px;
	}

	.contact-us {
		.title {
			font-family: 'Outfit';
			font-style: normal;
			font-weight: 400;
			font-size: 26.4px;
			line-height: 30px;
			color: black;
			position: relative;
			top: 2.5px;

			@media only screen and (max-width: 975px) {
				font-size: 20.4px !important;
			}

			@media (max-width: 595px) {
				font-size: 18px !important;
			}
		}
		.contact-us-content {
			margin-left: 18px;

			.text {
				margin-left: 2px;
				font-family: 'Inter';
				font-style: normal;
				font-weight: 400;
				font-size: 12px;
				line-height: 150%;
				color: black;

				@media only screen and (max-width: 975px) {
					font-size: 17px;
				}

				@media (max-width: 595px) {
					font-size: 14px;
				}
			}
		}
	}

	.info-box {
		.term {
			font-size: 14px;
			margin-bottom: 25px;

			@media only screen and (max-width: 975px) {
				font-size: 12px;
			}

			@media (max-width: 595px) {
				font-size: 11px;
			}

			.vertical-line {
				width: 0.5px;
				height: 15px;
				margin: 0 15px;
				background-color: black;

				@media only screen and (max-width: 975px) {
					height: 13px;
				}

				@media (max-width: 595px) {
					height: 11px;
				}
			}

			a {
				color: black;
			}
		}

		.company-info {
			font-weight: 400;
			font-size: 14px;
			line-height: 180%;
			color: rgba(0, 0, 0, 0.4);

			@media only screen and (max-width: 975px) {
				font-size: 13px;
			}

			@media (max-width: 595px) {
				font-size: 11px;
			}
		}
	}
}
</style>
