var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"footer-wrapper"},[_c('v-container',[_c('div',{staticClass:"flex contact-us",staticStyle:{"width":"100%"}},[_c('v-card',{staticStyle:{"background-color":"rgb(244, 244, 248)"},attrs:{"flat":""}},[_c('v-card-title',{staticClass:"d-flex align-start justify-space-between px-0"},[_c('div',{staticClass:"d-flex align-start mb-4"},[_c('v-img',{attrs:{"src":require('@/assets/logo/logo.png'),"contain":"","width":"46px"}}),_c('div',{staticClass:"contact-us-content"},[_c('div',{staticClass:"title"},[_vm._v("CONTACT US")]),_c('div',{staticClass:"text"},[_vm._v("마이페이지 상담문의를 통해 문의해주세요")])])],1),_c('div',{staticClass:"info-box"},[_c('div',{staticClass:"term d-flex"},[_c('router-link',{staticClass:"text-decoration-none",attrs:{"to":{
									name: 'term',
									params: {
										type: 'service-next',
									},
								}}},[_vm._v(" 이용 약관 ")]),_c('div',{staticClass:"vertical-line"}),_c('router-link',{staticClass:"text-decoration-none",attrs:{"to":{
									name: 'term',
									params: {
										type: 'privacy',
									},
								}}},[_vm._v(" 개인정보 처리방침 ")])],1),_c('small',{staticClass:"company-info"},[_vm._v(" "+_vm._s(_vm.companyInfo.name)+" "),_c('br'),_vm._v(" 대표 "+_vm._s(_vm.companyInfo.ceoName)+" "),_c('br'),_vm._v(" 사업자 등록번호 "+_vm._s(_vm.companyInfo.registrationNumber)+" | 통신판매업 "+_vm._s(_vm.companyInfo.mailOrderNumber)+" "),_c('br'),_vm._v(" 위치 "+_vm._s(_vm.companyInfo.address)+" "),_c('br'),_vm._v(" "+_vm._s(_vm.companyInfo.tel)+" ")])])])],1)],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }