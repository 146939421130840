<template>
	<div class="main">
		<full-page :options="options" id="fullpage" ref="fullpage">
			<div
				class="section contest-intro"
				:style="{ backgroundImage: 'url(' + bannerImage.imageUrl + ')' }"
			></div>
			<div class="section we_are">
				<v-container>
					<div class="full-width d-flex">
						<div class="title">
							<em class="em">WE ARE</em>
							<span v-html="$t('weAre.title')"></span>
						</div>
					</div>
					<div class="inner-container">
						<ul>
							<li class="card-wrapper">
								<v-card class="card we_are-card">
									<img :src="require('@/assets/images/main/marketer.svg')" />
									<v-card-title>
										<span v-html="$t('weAre.card.title1')"></span>
									</v-card-title>
								</v-card>
							</li>
							<li class="card-wrapper">
								<v-card class="card we_are-card">
									<img :src="require('@/assets/images/main/business.svg')" />
									<v-card-title>
										<span v-html="$t('weAre.card.title2')"></span>
									</v-card-title>
								</v-card>
							</li>
							<li class="card-wrapper">
								<v-card class="card we_are-card">
									<img :src="require('@/assets/images/main/system.svg')" />
									<v-card-title>
										<span v-html="$t('weAre.card.title3')"></span>
									</v-card-title>
								</v-card>
							</li>
							<li class="card-wrapper">
								<v-card class="card we_are-card">
									<img :src="require('@/assets/images/main/explorer.svg')" />
									<v-card-title>
										<span v-html="$t('weAre.card.title4')"></span>
									</v-card-title>
								</v-card>
							</li>
						</ul>
					</div>
				</v-container>
				<a href="#section3" class="btn_round primary">
					<span class="txt">We Do</span>
					<i class="ico_btn_round"></i>
				</a>
			</div>
			<div class="section we_do">
				<v-container>
					<div class="full-width d-flex">
						<div class="title">
							<em class="em">WE DO</em>
							<span v-html="$t('weDo.title')"></span>
						</div>
					</div>
					<div class="inner-container">
						<ul>
							<li class="card-wrapper">
								<v-card class="card we_do-card">
									<img :src="require('@/assets/images/main/create.svg')" />
									<v-card-title>
										<span v-html="$t('weDo.card.title1')"></span>
									</v-card-title>
								</v-card>
							</li>
							<li class="card-wrapper">
								<v-card class="card we_do-card">
									<img :src="require('@/assets/images/main/citizen.svg')" />
									<v-card-title>
										<span v-html="$t('weDo.card.title2')"></span>
									</v-card-title>
								</v-card>
							</li>
							<li class="card-wrapper">
								<v-card class="card we_do-card">
									<img :src="require('@/assets/images/main/discovery.svg')" />
									<v-card-title>
										<span v-html="$t('weDo.card.title3')"></span>
									</v-card-title>
								</v-card>
							</li>
							<li class="card-wrapper">
								<v-card class="card we_do-card">
									<img :src="require('@/assets/images/main/data.svg')" />
									<v-card-title>
										<span v-html="$t('weDo.card.title4')"></span>
									</v-card-title>
								</v-card>
							</li>
						</ul>
					</div>
				</v-container>
			</div>
			<div class="section fp-auto-height footer">
				<main-footer />
			</div>
		</full-page>
	</div>
</template>
<script>
import { ref, reactive, onMounted } from '@vue/composition-api'

import store from '@/store'
import { useRouter } from '@core/utils'

import NoticeService from '@/services/NoticeService'

import MainFooter from './MainFooter'

export default {
	components: {
		MainFooter,
	},
	setup() {
		const { route } = useRouter()
		const bannerImage = ref({
			imageUrl:
				'https://factory-data-history.s3.ap-northeast-2.amazonaws.com/static/notices/34/file/220111_contents-market.png',
			backgroundColor: '#5F75BF',
		})
		const fullpageOnLeave = (_, destination) => {
			store.dispatch('app/setIsLogoWhite', true)
			if (
				destination.anchor === null ||
				destination.anchor === 'section2' ||
				destination.anchor === 'section4'
			) {
				store.dispatch('app/setIsLogoWhite', false)
			}
		}
		const options = reactive({
			onLeave: fullpageOnLeave,
			scrollingSpeed: 1200,
			menu: '#menu',
			licenseKey: `'${process.env.VUE_APP_FULLPAGE_JS_LICENSE_KEY}'`,
			anchors: ['section1', 'section2', 'section3', 'section4'],
			sectionsColor: ['#010A18', '#F4F4F8', '#060539', '#F4F4F8'],
		})
		const notices = ref([])

		const getLatestNotices = async () => {
			try {
				const data = await NoticeService.getLatestNotices()
				notices.value = data.filter(e => e.visible === true)
			} catch (e) {
				console.error(e)
			}
		}
		getLatestNotices()

		const setNextContent = () => {
			document.querySelector('.intro .title-wrapper > .title').style.display =
				'none'
			document.querySelector('.intro .title-wrapper').classList.add('second')
			document.querySelector(
				'.intro .title-wrapper > .second-title',
			).style.display = 'block'

			document.getElementById('video').style.opacity = 0
			document.getElementById('video').style.transition =
				'visibility 0s, opacity 1s linear'
			document.getElementById('video2').style.visibility = 'visible'
			document.getElementById('video2').style.opacity = 1

			// eslint-disable-next-line no-use-before-define
			setTimeout(setContent, 3500)
		}

		const setContent = () => {
			document.querySelector('.intro .title-wrapper > .title').style.display =
				'block'
			document.querySelector('.intro .title-wrapper').classList.remove('second')
			document.querySelector(
				'.intro .title-wrapper > .second-title',
			).style.display = 'none'

			document.getElementById('video').style.opacity = 1
			document.getElementById('video').style.visibility = 'visible'
			document.getElementById('video2').style.opacity = 0
			document.getElementById('video2').style.transition =
				'visibility 0s, opacity 1s linear'

			setTimeout(setNextContent, 3500)
		}

		onMounted(() => {
			store.dispatch('app/setIsLogoWhite', true)
			if (
				route.value.fullPath.includes('section2') ||
				route.value.fullPath.includes('section4')
			) {
				store.dispatch('app/setIsLogoWhite', false)
			}
		})

		return {
			notices,
			options,
			bannerImage,
		}
	},
}
</script>
<style lang="scss" scoped>
@import '~@/assets/css/main.scss';
</style>
